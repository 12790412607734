import React, { Fragment, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import { getInstructorsHistory, axiosGetExportRequest, getRoleName } from './../../helpers'
import Cookies from 'js-cookie';
import { getSudomain } from './../../../helpers'
import Pagination from "react-js-pagination";
import ReactTooltip from "react-tooltip";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom';
import TableSortingHeader from '../tableSortingHeader';

export default function InstructorHistory(props){
  const { analyticsInstructors, historyUserId, historyUserName, historyStartDate, historyEndDate, historyType, setHistoryStartDate, setHistoryEndDate, user } = props

  const date = new Date();
  date.setMonth(date.getMonth() - 1, 1);

  const [ showAnalyticsInstructorslist, setShowAnalyticsInstructorslist ] = useState(false)
  const [ searchAnalyticsInstructor, setSearchAnalyticsInstructor ] = useState(historyUserName || '')
  const [ analyticsInstructor , setAnalyticsInstructor ] = useState([])
  const [ selectedAnalyticsInstructor, setSelectedAnalyticsInstructor ] = useState(historyUserId || '')
  const [ startDate, setStartDate ] = useState(historyStartDate || date)
  const [ endDate, setEndDate ] = useState(historyEndDate || new Date())
  const [ selectedExtraFilters, setselectedExtraFilters ] = useState(historyType || 'sent')
  const [ activePage, setActivePage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(20)
  const [ sortType, setSortType ] = useState('title')
  const [ sortReverse, setSortReverse ] = useState(true)
  const [ instructorHistoryData, setInstructorHistoryData ] = useState([])
  const [ activeTable, setActiveTable ] = useState('sent')
  const [ totalEvents, setTotalEvents ] = useState(0)
  const [ baseCount, setBaseCount ] = useState(0)
  const extraFiltersList = [
    {
      key: 'sent', label: 'Sub Requests Sent'
    },
    {
      key: 'receive', label: 'Sub Requests Received'
    },
    {
      key: 'subbed', label: 'Classes Subbed'
    }
  ]

  const handleClosepeopleList = (e) => {
    const target = $(e.target)
    if(!target.parents('div#targetshowAnalyticsInstructorslist1').length && !target.parents('div#showAnalyticsInstructorslistInput').length && showAnalyticsInstructorslist){
      setShowAnalyticsInstructorslist(false)
        // showAnalyticsInstructorslist: false,
        // searchDmValue: ''
    }
  }

  useEffect(() => {
    handleFetchHistory()
  },[])

  useEffect(() => {
    if (searchAnalyticsInstructor) {
      document.body.addEventListener('click', handleClosepeopleList)
    }
    return () => document.body.removeEventListener('click', handleClosepeopleList)
  }, [handleClosepeopleList, searchAnalyticsInstructor]);

  const searchAnalyticsInstructors = (e) => {
    let searchTerm = e.target.value.trim()
    setSearchAnalyticsInstructor(e.target.value)
    let classes = analyticsInstructors
    if(searchTerm === ''){
      setAnalyticsInstructor([])
    }else{
      var foundClasses = classes.filter(function (entry) {
        return (entry.full_name.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      if (foundClasses.length > 0){
        setShowAnalyticsInstructorslist(true)
      }
      setAnalyticsInstructor(foundClasses)
    }
  }

  const handleOnBlurAnalyticsInstructors = (e) => {
    setShowAnalyticsInstructorslist(true)
  }

  const handleOnCLickAnalyticsInstructors = (e) => {
    setShowAnalyticsInstructorslist(true)
    searchAnalyticsInstructors(e)
  }

  const handleSelectedAnalyticsInstructor = (e, fullName, userId) => {
    setSelectedAnalyticsInstructor(userId)
    setSearchAnalyticsInstructor(fullName)
    setShowAnalyticsInstructorslist(false)
    fetchData(userId, startDate, endDate, selectedExtraFilters, 1, pageSize, sortType, sortReverse)
  }

  const handleStartDateChange = (e) => {
    setStartDate(e === null ? '' : e)
    setHistoryStartDate(e === null ? '' : e)
  }

  const handleEndDateChange = (e) => {
    setEndDate(e === null ? '' : e)
    setHistoryEndDate(e === null ? '' : e)
  }

  const handleFetchHistory = () => {
    // e.preventDefault();
    if (selectedAnalyticsInstructor == '' || startDate == '' || endDate == '' || selectedExtraFilters == '' || activePage == '' || pageSize == ''){
      console.log("Empty call..")
      return
    }
    
    fetchData(selectedAnalyticsInstructor, startDate, endDate, selectedExtraFilters, 1, pageSize, sortType, sortReverse)

  }

  const fetchData = (selectedAnalyticsInstructor, startDate, endDate, selectedExtraFilters, activePage, pageSize, sortType, sortReverse) => {
    getInstructorsHistory(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), selectedAnalyticsInstructor, startDate, endDate, selectedExtraFilters, activePage, pageSize, sortType, sortReverse).then(      
			response => response.json()
		)
		.then(result => {
			if(result.status === 200){
        setActivePage(parseInt(result?.page))
        setPageSize(parseInt(result?.page_size))
        setTotalEvents(result?.total)
        setInstructorHistoryData(result?.results)
        setActiveTable(result?.type)
        setBaseCount((parseInt(result.page)-1)*parseInt(result?.page_size))
			}else{
			}
		})
  }

  const handleSort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }
    setSortType(param)
    setSortReverse(sort_desc)
    fetchData(selectedAnalyticsInstructor, startDate, endDate, selectedExtraFilters, activePage, pageSize, param, sort_desc)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    fetchData(selectedAnalyticsInstructor, startDate, endDate, selectedExtraFilters, pageNumber, pageSize, sortType, sortReverse)
  }

  const handlExportHistoryData = () => {
    axiosGetExportRequest(`/api/v3/analytics/export_history?id=${selectedAnalyticsInstructor}&start_date=${startDate}&end_date=${endDate}&type=${selectedExtraFilters}&sort_type=${sortType}&sort_reverse=${sortReverse}`).then(result => { 
      const url = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `instructor_history.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)        
    })
  }

  const checkTypeName = (type) => {
    let classes =  extraFiltersList;
    let result = classes.filter((item) => {return item?.key.toString() === type})
    return result[0]?.label;
  }

  return(
    <Fragment>
      {/* <header>
        <h1 className='text-2xl font-semibold leading-tight text-gray-900'>Instructor History</h1>
      </header> */}
      <div className='flex gap-4 py-4 flex-wrap'>
        <div id='showAnalyticsInstructorslistInput' className="relative bg-gray-300 rounded-md sm:w-80 w-full">
          <div className='bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center'>
            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
            <input type="text" className='text-sm w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent' placeholder='Search to get instructor request history' value={searchAnalyticsInstructor} onChange={(e) => searchAnalyticsInstructors(e)} onBlur={(e) => handleOnBlurAnalyticsInstructors(e)} onClick={(e) => handleOnCLickAnalyticsInstructors(e)} />
          </div>
          {(analyticsInstructor?.length > 0 && showAnalyticsInstructorslist) &&
            <div id='targetshowAnalyticsInstructorslist1' className="relative block text-left">
              <div className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                <div className="py-2 max-h-60 overflow-y-auto">
                  <div>
                    {analyticsInstructor.map((user, i) => 
                      <a key = {i} className="flex items-center text-gray-900 text-sm px-3 cursor-pointer hover:bg-gray-100 py-2.5" onClick= {(e) => handleSelectedAnalyticsInstructor(e, user?.full_name, user?.id)}>
                        <div className='pl-1'>{user?.full_name}</div>
                      </a>
                    )}
                    {analyticsInstructor?.length === 0 &&
                      <div className='text-gray-400 text-center'>No user found</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className='flex items-center sm:w-60 w-full'>
          <div className='my-2.5 mr-2.5'>
            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip="true" currentitem="false" data-for={`instructor_history_tooltip`}><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path></svg>
            <ReactTooltip className='max-w-xs text-center' id={`instructor_history_tooltip`} place="top" effect="solid">
              <p>{"The dates represent the class dates. You will be looking at the number of sub requests sent, received, or approved for this instructor for classes occurring in the selected timeframe."}</p>
            </ReactTooltip>
          </div>
          <div className='w-full bg-white relative border border-gray-300 shadow-sm rounded-md h-9 flex items-center '>
            {/* <input type="text" className='text-sm w-full border-0 h-9 ml-2 focus:outline-none bg-transparent' placeholder='Start Date' /> */}
            <DatePicker
              selected={startDate}
              className="h-9 w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
              autoFocus={false}
              dateFormat="M/d/yyyy"
              onChange={(e) => handleStartDateChange(e)}
              calendarType="US" 
              // isClearable={startDate === '' ? false : true}
              placeholderText="Start Date"                    
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-1.5 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
        </div>
        <div className='bg-white relative border border-gray-300 shadow-sm rounded-md h-9 flex items-center sm:w-60 w-full'>
          {/* <input type="text" className='text-sm w-full border-0 h-9 ml-2 focus:outline-none bg-transparent' placeholder='End Date' /> */}
          <DatePicker
            selected={endDate}
            className="h-9 w-full border-0 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
            autoFocus={false}
            dateFormat="M/d/yyyy"
            onChange={(e) => handleEndDateChange(e)}
            calendarType="US" 
            // isClearable={startDate === '' ? false : true}
            placeholderText="End Date"                    
          />
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 absolute top-1.5 right-2.5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
          </svg>
        </div>
        <div className='bg-white rounded-md h-9 flex items-center sm:w-60 w-full filters_box'>
          <Menu as="div" className="w-full relative block text-left ">
            <div>
              <Menu.Button className="inline-flex items-center h-9 justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                {selectedExtraFilters === '' ? 
                  "Select type"
                : 
                  <p className = "text-gray-900">{checkTypeName(selectedExtraFilters)}</p>
                }
                <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right z-20 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                <div className="py-2 max-h-60 overflow-y-auto">
                  <div>
                    {extraFiltersList.map((type, i) => 
                      <Menu.Item key = {i}>
                        {({ active }) => (
                          <a className="flex items-center text-gray-600 text-sm cursor-pointer pl-4 hover:bg-gray-100 hover:text-gray-900 py-1.5 font-medium" name="selectedType" onClick = {(e) => {setselectedExtraFilters( type?.key); setSortType('title'); setSortReverse(true)}}>
                            {type?.label}
                          </a>
                            
                        )}
                      </Menu.Item>
                    )}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div className='sm:w-36'>
          <div className="flex items-center gap-x-4">
            <input type="submit" value="Search" className={`font-medium inline-flex text-sm text-white py-2 px-3 rounded-md h-9 ${selectedAnalyticsInstructor == "" ? 'cursor-not-allowed bg-gray-500' : 'cursor-pointer bg-dark-blue'}`} onClick={(e) => handleFetchHistory(e)} disabled={selectedAnalyticsInstructor == ""}/>
            <button className={`${selectedAnalyticsInstructor == "" ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handlExportHistoryData()} disabled={selectedAnalyticsInstructor == ""}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`w-6 h-6 ${selectedAnalyticsInstructor == "" ? 'text-gray-500' : 'text-black'}`}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {activeTable == 'sent' &&
        <div className='overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 mb-4 Sub-Request-sent'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h300'>
            <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Class Date & Time</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'start_date_time'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Day of Week</span>&nbsp; &nbsp;
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Class Name</span> &nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'title'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Class Duration (Mins)</span>
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Location (Region)</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'location'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Original Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'original_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Re-opened Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Created Date</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_at'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Created By</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_by'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Days Notice</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'days_before'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 w-80'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Reason for Sub Request</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'class_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Reason for Reopen</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Status of Class</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'display_status'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Approved Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'approved_user'} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {instructorHistoryData.map((history, i) => 
                    <tr key={i} className='bg-white'>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.start_date_time}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.day_of_week}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-center'>
                          <Link to={`/sub-request-details/${history?.id}`} className='text-blue-600'>{history?.class_name}</Link>  
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.duration_display}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.location}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.original_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopened_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.requested}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.created_by}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.days_notice}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reason} 
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopen_reason} 
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.status}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.approved_instructor}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      {activeTable == 'receive' &&
        <div className='overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 mb-4 Sub-request-Received'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h300'>
            <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Class Date & Time</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'start_date_time'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Day of Week</span>&nbsp; &nbsp;
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Class Name</span> &nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'title'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Class Duration (Mins)</span>
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Location (Region)</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'location'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Requesting Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'requesting_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Re-opened Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Created Date</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_at'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Created By</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_by'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Days Notice</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'days_before'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Reason for Sub Request</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'class_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Reason for Reopen</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Response</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'response'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Approved as Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'approved_as_instructor'} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {instructorHistoryData.map((history, i) =>
                    <tr key={i} className='bg-white'>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.start_date_time}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.day_of_week}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-center'>
                          <Link to={`/sub-request-details/${history?.id}`} className='text-blue-600'>{history?.class_name}</Link>  
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.duration_display}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.location}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.requesting_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopened_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.requested}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.created_by}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.days_notice}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reason}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopen_reason}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.response}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.approved_as_instructor}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      {activeTable == 'subbed' &&
        <div className='overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 mb-4 Classes-Subbed'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 min-h300'>
            <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Class Date & Time</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'start_date_time'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Day of Week</span>&nbsp; &nbsp;
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Class Name</span> &nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'title'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm whitespace-nowrap'>Class Duration (Mins)</span>
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Location (Region)</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'location'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Requesting Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'requesting_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Re-opened Instructor</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_instructor'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Created Date</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_at'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Created By</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'created_by'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Days Notice</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'days_before'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Reason for Sub Request</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'class_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Reason for Reopen</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'reopened_reason'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>Status of Class</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'display_status'} />
                      </div>
                    </th>
                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500'>
                      <div className='flex items-center whitespace-nowrap'>
                        <span className='cursor-pointer text-sm'>No Show?</span>&nbsp; &nbsp;
                        <TableSortingHeader sortType={sortType} sortReverse={sortReverse} sort={handleSort} sortName={'no_show'} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {instructorHistoryData.map((history, i) =>
                    <tr key={i} className='bg-white'>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.start_date_time}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.day_of_week}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-center'>
                          <Link to={`/sub-request-details/${history?.id}`} className='text-blue-600'>{history?.class_name}</Link>  
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.duration_display}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.location}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.requesting_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopened_instructor}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.requested}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.created_by}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.days_notice}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reason}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.reopen_reason}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.status}
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm text-gray-500 text-center'>
                          {history?.no_show?.toString()}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      <div className='py-3 mt-2 flex items-center justify-between'>
        <div className='flex justify-between w-full flex-wrap'>
          <div>
            <p className='text-sm text-gray-700'>
              {`Showing ${totalEvents == 0 ? totalEvents : baseCount+1} to ${activePage*pageSize > totalEvents ? totalEvents : activePage*pageSize} of ${totalEvents} results`}
            </p>
          </div>
        </div>
        <div className='custom-pagination order-first sm:order-last mb-3 sm:mb-0'>
          {totalEvents > pageSize && 
            <Pagination
              activePage={activePage}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalEvents}
              pageRangeDisplayed={9}
              onChange={handlePageChange}
              itemClassPrev='previous'
              itemClassNext='next'
              itemClassFirst='first'
              itemClassLast='last'
              hideDisabled={true}
            />
          }
        </div>
      </div>
    </Fragment>
  )
}