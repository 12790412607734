import React from "react";
import SortingIcons from "../../documentations/assets/sortingIcons";

export default function TableSortingHeader({sortType, sortReverse, sort, sortName}) {
  return (
    <div className='flex items-center'>
      {sortType !== sortName &&
        <SortingIcons iconName={''} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, 'desc')}/>
      }
      {(sortType === sortName && sortReverse) &&
        <SortingIcons iconName={'desc'} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, 'asc')}/>
      }
      {(sortType === sortName && !sortReverse) &&
        <SortingIcons iconName={'asc'} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, 'desc')}/>
      }
    </div>
  )
}